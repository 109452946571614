/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef, useState } from 'react';

import { useIntersection } from '@common/hooks/useIntersection';
import useTranslate from '@common/hooks/useTranslate';
import { fadeIn } from '@common/keyframes';
import Button from '@stories/atoms/Button';
import { container } from '@stories/atoms/Grid';
import CategoryCard from '@stories/molecules/CategoryCard';

import type { IntermediaryCategoryDto } from '@vitafy/storefront-api-contracts-fetch';

const translations = {
  'show all': {
    de_DE: 'Alle Produkte anzeigen'
  }
};

export type TCategoryListProps = IntermediaryCategoryDto;

const categoryListStyle = {
  mainDiv: () =>
    css({
      ...container,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }),
  listOfCategories: css({
    display: 'flex',
    columnGap: '2rem',
    rowGap: '2rem',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  }),
  childContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  })
};

const CategoryList: React.FC<TCategoryListProps> = ({ children, url }) => {
  const categoryContainerRef = useRef<HTMLDivElement>(null);
  const translate = useTranslate(translations);
  const [isIntersecting, setIsIntersecting] = useState(false);
  useIntersection(categoryContainerRef, () => setIsIntersecting(true), {
    name: 'noThreshold',
    rootMargin: '0px',
    threshold: 0.3
  });

  return (
    <>
      <div css={categoryListStyle.childContainer}>
        <div
          data-testid="categories-list"
          css={categoryListStyle.listOfCategories}
          ref={categoryContainerRef}
        >
          {children.map((category, index) => (
            <CategoryCard
              key={category.id}
              cssProps={{
                ...(isIntersecting
                  ? { animation: `${fadeIn} 350ms ${~~(index / 2) * 150 + 50}ms ease-out both` }
                  : { opacity: 0 })
              }}
              {...category}
            />
          ))}
        </div>
      </div>
      {url && (
        <div css={{ margin: '3rem auto 0', maxWidth: '20rem' }}>
          <Button label={translate('show all')} href={url === '/search' ? url : '/category/' + url} />
        </div>
      )}
    </>
  );
};

export default CategoryList;
