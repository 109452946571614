/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Image from 'next/image';
import { useContext, useRef } from 'react';

import { linkHover } from '@common/cssUtilities';
import { useIntersection } from '@common/hooks/useIntersection';
import { buildMediaUrl, makeUrlAbsolute, sendGAEvent } from '@common/utlis';
import { LinkSwitched } from '@stories/atoms/Link';
import { PageContext } from '@stories/templates/Context/pageContext';
import { media, mediaDown, mq } from '@stories/theming/settings';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';
import type { CategoryFromDBChildDto } from '@vitafy/storefront-api-contracts-fetch';

const categoryCardStyle = {
  mainDivFn: () =>
    ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '1rem',
      position: 'relative',
      width: '100%',
      ':last-child': {
        borderBottom: 'none'
      },
      [media('desktop')]: {
        width: '48%',
        ':nth-last-child(2)': {
          borderBottom: 'none'
        }
      }
    }) as const,
  label: (t) =>
    css({
      fontWeight: 'bold',
      lineHeight: '100%',
      marginBottom: '.375rem',
      [media('desktop')]: {
        textAlign: 'start',
        display: 'flex',
        '&:hover': {
          textDecoration: 'underline',
          color: t.color.hover.copy
        }
      },
      [mediaDown('desktop')]: {
        '::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 1
        }
      }
    })
};

const trackClick = ({ id, name, level = 1 }) => {
  sendGAEvent({
    category: 'Navigation',
    action: `ICP category card click - level ${level}`,
    label: `${id} - ${name}`
  });
};

type TSubcategoriesProps = {
  subcategories: CategoryFromDBChildDto[];
};

const Subcategories: React.FC<TSubcategoriesProps> = ({ subcategories }) => {
  const { storeCode } = useContext(PageContext);

  return (
    <div css={{ lineHeight: '120%', position: 'relative', textUnderlineOffset: '2px' }}>
      {/* lineClamp doesn't work on iOs when children are elements and not text. Assuming line-height 22px */}
      <ul css={mq({ overflow: 'hidden', maxHeight: ['66px', null, null, '88px'] })}>
        {subcategories.map(({ id, name, type, url }) => (
          <li
            key={id}
            css={{
              display: 'inline-block',
              ':not(:last-child)::after': { content: '"·"', display: 'inline-block', margin: '0 .25rem' }
            }}
          >
            <LinkSwitched
              href={makeUrlAbsolute(url)}
              query={{ store: storeCode, categoryId: id }}
              pathname={`/[store]/${type}/[categoryId]`}
              cssProps={(t) => ({
                color: t.color.grey.g40,
                fontSize: t.font.size.small,
                '&:hover': linkHover(t)
              })}
              attrs={{ onClick: () => trackClick({ id, name, level: 2 }) }}
            >
              {name}
            </LinkSwitched>
          </li>
        ))}
      </ul>
    </div>
  );
};

type TCategoryCard = CategoryFromDBChildDto & {
  cssProps?: Interpolation<Theme>;
};

const CategoryCard: React.FC<TCategoryCard> = ({
  cssProps,
  id,
  name,
  type,
  url,
  thumbnail,
  products_count,
  children,
  category_default_filter
}) => {
  const { storeCode } = useContext(PageContext);
  const cardRef = useRef<HTMLDivElement>(null);

  useIntersection(cardRef, () =>
    sendGAEvent({ category: 'Navigation', action: 'ICP category card impression', label: `${id} - ${name}` })
  );
  return (
    <div
      css={() => ({ ...categoryCardStyle.mainDivFn(), ...(cssProps as object) })}
      ref={cardRef}
      className="vf-category-card"
      data-category-id={id}
    >
      <LinkSwitched
        pathname={`/[store]/${type}/[categoryId]`}
        query={{
          store: storeCode,
          categoryId: id,
          ...(category_default_filter ? { sort: category_default_filter } : {})
        }}
        href={makeUrlAbsolute(url + (category_default_filter ? `?sort=${category_default_filter}` : ''))}
        cssProps={{
          flexShrink: 0,
          borderRadius: '8px',
          boxShadow: '0 0 8px -1px hsl(0 0% 0% / 27% )',
          display: 'block',
          overflow: 'hidden',
          height: '6rem',
          position: 'relative',
          width: '6rem',
          [media('desktop')]: {
            height: '8rem',
            width: '8rem'
          }
        }}
        attrs={{
          onClick: () => trackClick({ id, name })
        }}
      >
        {thumbnail !== null ? (
          <Image
            src={buildMediaUrl(`/media/catalog/category/${thumbnail}`)}
            alt={name}
            fill={true}
            sizes="(min-width: 1024px) 96px, 64px"
          />
        ) : (
          <div css={(t) => ({ backgroundColor: t.color.grey.g92 })}></div>
        )}
      </LinkSwitched>
      <div>
        <LinkSwitched
          pathname={`/[store]/${type}/[categoryId]`}
          query={{
            store: storeCode,
            categoryId: id,
            ...(category_default_filter ? { sort: category_default_filter } : {})
          }}
          href={makeUrlAbsolute(url + (category_default_filter ? `?sort=${category_default_filter}` : ''))}
          cssProps={categoryCardStyle.label}
          attrs={{
            onClick: () => trackClick({ id, name })
          }}
        >
          {`${name}\u00a0`}
          {products_count && <span>{`(${products_count})`}</span>}
        </LinkSwitched>
        <Subcategories subcategories={children} />
      </div>
    </div>
  );
};

export default CategoryCard;
